// src/pages/OrderHistoryPage.tsx

import PlaceholderPage from '../components/PlaceholderPage';

function OrderHistoryPage() {
  return <PlaceholderPage 
    title="Order History" 
    description="View and manage historical order data, track order statuses, and access order details."
  />;
}

export default OrderHistoryPage;
