// src/components/picklist/PickListOrdersModal.tsx
import React from 'react';
import { Package } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface PickListOrdersModalProps {
  pickList: {
    id: string;
    sequence: number;
    orders: Array<{
      order: {
        orderNumber: string;
        priority: string;
        customer: {
          name: string;
          email: string;
        };
        items: Array<{
          productTitle: string;
          variantTitle?: string;
          quantity: number;
        }>;
      };
    }>;
  } | null;
  isOpen: boolean;
  onClose: () => void;
}

const PickListOrdersModal: React.FC<PickListOrdersModalProps> = ({
  pickList,
  isOpen,
  onClose,
}) => {
  if (!pickList) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Pick List #{pickList.sequence} Orders</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {pickList.orders.map((po) => (
            <div
              key={po.order.orderNumber}
              className="border rounded-lg p-4 bg-white"
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <span className="font-medium">Order #{po.order.orderNumber}</span>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    po.order.priority === 'HIGH' 
                      ? 'bg-red-100 text-red-800'
                      : po.order.priority === 'URGENT'
                      ? 'bg-red-200 text-red-900'
                      : 'bg-blue-100 text-blue-800'
                  }`}>
                    {po.order.priority}
                  </span>
                </div>
              </div>

              <div className="text-sm text-gray-600 mb-3">
                {po.order.customer.name} ({po.order.customer.email})
              </div>

              <div className="space-y-2">
                {po.order.items.map((item, index) => (
                  <div key={index} className="flex items-center space-x-2 text-sm">
                    <Package className="h-4 w-4 text-gray-400" />
                    <span>
                      {item.quantity}x {item.productTitle}
                      {item.variantTitle && (
                        <span className="text-gray-500"> ({item.variantTitle})</span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PickListOrdersModal;