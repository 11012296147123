// packages/frontend/src/pages/OperationsPickListPage.tsx

import OperationsPickListView from '../components/picklist/OperationsPickListView';

const OperationsPickListPage = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Active Pick List</h1>
      <OperationsPickListView />
    </div>
  );
};

export default OperationsPickListPage;