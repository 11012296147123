// packages/frontend/src/pages/AdminPickListPage.tsx

import AdminPickListManager from '../components/picklist/AdminPickListManager';

const AdminPickListPage = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Pick List Management</h1>
      <AdminPickListManager />
    </div>
  );
};

export default AdminPickListPage;