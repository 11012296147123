
import CreateOrderForm from '@/components/CreateOrderForm';

const CreateOrderPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">Create Manual Order</h1>
          <p className="mt-1 text-sm text-gray-600">
            Create orders for B2B customers, replacements, or special circumstances
          </p>
        </div>
        <CreateOrderForm />
      </div>
    </div>
  );
};

export default CreateOrderPage;