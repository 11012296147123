// src/stores/authStore.ts
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { login as apiLogin, getProfile } from '../api/auth';
import { AuthState } from '../types/auth';

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      error: null,
      token: null,  // Initialize token in state
      login: async (credentials) => {
        try {
          set({ error: null });
          const response = await apiLogin(credentials);
          
          if (!response.data.user) {
            throw new Error('No user data in response');
          }

          const user = response.data.user;
          const token = response.data.token;
          
          // Store token in both localStorage and state
          localStorage.setItem('token', token);
          
          set({
            user,
            token,
            isAuthenticated: true,
            error: null,
          });
        } catch (error: any) {
          set({
            error: error.response?.data?.message || 'Login failed',
            isAuthenticated: false,
            user: null,
            token: null,
          });
          throw error;
        }
      },
      logout: () => {
        localStorage.removeItem('token');
        set({
          user: null,
          isAuthenticated: false,
          error: null,
          token: null,
        });
      },
      checkAuth: async () => {
        try {
          const token = localStorage.getItem('token');
          
          if (!token) {
            set({ 
              user: null, 
              isAuthenticated: false, 
              error: null,
              token: null,
            });
            return;
          }

          const response = await getProfile();

          const userData = {
            id: response.data.id,
            email: response.data.email,
            role: response.data.role,
            name: response.data.name
          };

          set({
            user: userData,
            isAuthenticated: true,
            error: null,
            token,  // Keep token in state
          });
        } catch (error) {
          localStorage.removeItem('token');
          set({
            user: null,
            isAuthenticated: false,
            error: 'Authentication failed',
            token: null,
          });
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ 
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        token: state.token  // Include token in persisted state
      }),
    }
  )
);
