// packages/frontend/src/components/NavigationLayout.tsx

import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { 
  Home, 
  Package, 
  ClipboardList, 
  Settings, 
  List,
  CheckSquare,
  Database,
  BarChart2,
  Users,
  PlusCircle,
  Menu,
  X
} from 'lucide-react';
import { useAuthStore } from '../stores/authStore';

interface NavigationItem {
  name: string;
  href: string;
  icon: React.ElementType;
  allowedRoles: string[];
}

interface NavigationGroup {
  name?: string;
  items: NavigationItem[];
}

const LOGO_URL = "https://cdn.shopify.com/s/files/1/0725/2118/7621/files/Logo-Transparent-cropped.png?v=1683221164";

const NavigationLayout = () => {
  const location = useLocation();
  const { user } = useAuthStore();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigationGroups: NavigationGroup[] = [
    {
      // Core functionality
      items: [
        {
          name: 'Dashboard',
          href: '/',
          icon: Home,
          allowedRoles: ['admin', 'operator', 'viewer']
        },
        {
          name: 'Orders',
          href: '/orders',
          icon: Package,
          allowedRoles: ['admin', 'operator', 'viewer']
        },
        {
          name: 'Create Order',
          href: '/orders/create',
          icon: PlusCircle,
          allowedRoles: ['admin', 'operator']
        },
        {
          name: 'Production',
          href: '/production',
          icon: ClipboardList,
          allowedRoles: ['admin', 'operator']
        },
      ]
    },
    {
      name: 'Fulfillment',
      items: [
        {
          name: 'Manage Pick Lists',
          href: '/pick-lists/manage',
          icon: List,
          allowedRoles: ['admin']
        },
        {
          name: 'Active Pick List',
          href: '/pick-lists/active',
          icon: CheckSquare,
          allowedRoles: ['admin', 'operator']
        },
      ]
    },
    {
      name: 'Management',
      items: [
        {
          name: 'Inventory',
          href: '/inventory',
          icon: Database,
          allowedRoles: ['admin', 'operator']
        },
        {
          name: 'Analytics',
          href: '/analytics',
          icon: BarChart2,
          allowedRoles: ['admin']
        },
        {
          name: 'Customers',
          href: '/customers',
          icon: Users,
          allowedRoles: ['admin', 'operator']
        },
      ]
    },
    {
      name: 'System',
      items: [
        {
          name: 'Settings',
          href: '/settings',
          icon: Settings,
          allowedRoles: ['admin']
        },
      ]
    }
  ];

  const renderNavigation = () => (
    <nav className="flex-1 px-2 space-y-8 bg-white">
      {navigationGroups.map((group, groupIdx) => {
        const filteredItems = group.items.filter(item =>
          item.allowedRoles.includes(user?.role || '')
        );

        if (filteredItems.length === 0) return null;

        return (
          <div key={groupIdx}>
            {group.name && (
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                {group.name}
              </h3>
            )}
            <div className="space-y-1">
              {filteredItems.map((item) => {
                const Icon = item.icon;
                const isActive = location.pathname === item.href;
                
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`
                      group flex items-center px-2 py-2 text-sm font-medium rounded-md
                      ${isActive 
                        ? 'bg-gray-100 text-gray-900' 
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      }
                    `}
                  >
                    <Icon
                      className={`
                        flex-shrink-0 -ml-1 mr-3 h-5 w-5
                        ${isActive 
                          ? 'text-gray-500' 
                          : 'text-gray-400 group-hover:text-gray-500'
                        }
                      `}
                    />
                    {item.name}
                  </Link>
                );
              })}
            </div>
          </div>
        );
      })}
    </nav>
  );

  const renderLogo = () => (
    <div className="flex items-center justify-center p-4">
      <img 
        src={LOGO_URL} 
        alt="Everboost Logo" 
        className="h-12 w-auto"
      />
    </div>
  );

  return (
    <div className="flex h-screen bg-white">
      {/* Mobile menu button */}
      <div className="lg:hidden fixed top-0 left-0 z-50 p-4">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
        >
          {isMobileMenuOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* Mobile sidebar */}
      <div
        className={`fixed inset-0 z-40 lg:hidden ${
          isMobileMenuOpen ? 'block' : 'hidden'
        }`}
      >
        {/* Overlay */}
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75"
          onClick={() => setIsMobileMenuOpen(false)}
        />

        {/* Sidebar */}
        <div className="relative flex flex-col w-80 max-w-xs h-full bg-white">
          <div className="flex-1 pt-16 pb-4 overflow-y-auto">
            {renderLogo()}
            {renderNavigation()}
          </div>
        </div>
      </div>

      {/* Desktop sidebar */}
      <div className="hidden lg:flex lg:flex-col lg:w-64">
        <div className="flex flex-col flex-1 border-r border-gray-200 bg-white">
          <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
            {renderLogo()}
            {renderNavigation()}
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-col flex-1">
        <main className="flex-1 overflow-y-auto bg-white p-4 lg:p-6 pt-16 lg:pt-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default NavigationLayout;
