// packages/frontend/src/types/picklist.ts
import { Order, OrderStatus, OrderPriority } from './order';

export enum PickListStatus {
  PLANNED = 'PLANNED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export interface PickListOrder {
  id: string;
  order: Order;
  status: OrderStatus;
  sequence: number;
  completedAt?: string;
}

export interface PickList {
  id: string;
  status: PickListStatus;
  sequence: number;
  orders: PickListOrder[];
  completedAt?: string;
  labelsPrinted?: string;
  slipsPrinted?: string;
  plannedDate: Date;
}

export interface PickListOrderSelectorProps {
  selectedOrders: Order[];
  onOrdersSelected: (orders: Order[]) => void;
}

export interface OrderFilters {
  dateRange?: {
    from: Date;
    to: Date;
  };
  priority?: OrderPriority;
  customerName?: string;
  orderNumber?: string;
  productTitle?: string;
}

export interface PickListSummaryProps {
  orders: PickListOrder[];
}


