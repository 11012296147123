import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Loader, CheckCircle, AlertCircle } from 'lucide-react';
import { useToast } from '../components/ui/use-toast';
import { axiosInstance } from '../api/client';
import { PickListStatus } from '../types/order';
import type { Order, OrderItem } from '../types/order';

const VerifyOrderPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [orderData, setOrderData] = useState<Order | null>(null);
  const [pickListId, setPickListId] = useState<string | null>(null);
  const [verifiedItems, setVerifiedItems] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [orderVerified, setOrderVerified] = useState(false);

  const allItemsVerified = verifiedItems.every(Boolean);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        setLoading(true);
        const orderResponse = await axiosInstance.get(`/orders/${orderId}?include=pickList`);
        const order: Order = orderResponse.data.data;

        const activePickList = order.pickListOrders?.find(
          (plo) => plo.pickList?.status === PickListStatus.ACTIVE
        );

        if (!activePickList?.pickList?.id) {
          setError('No active pick list found for this order');
          return;
        }

        setPickListId(activePickList.pickList.id);
        setOrderData(order);
        setVerifiedItems(new Array(order.items.length).fill(false));
      } catch (err) {
        console.error('Error fetching order:', err);
        setError('Failed to load order details');
        toast({
          variant: "destructive",
          title: "Error",
          description: "Could not load order details",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [orderId, toast]);

  const handleVerify = async () => {
    if (!allItemsVerified) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please verify all items before proceeding",
      });
      return;
    }

    if (!pickListId || !orderData) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "No active pick list found for this order",
      });
      return;
    }

    try {
      setVerifying(true);

      const verifiedProductIds = verifiedItems
        .map((isVerified, index) => (isVerified ? orderData?.items[index]?.productId : null))
        .filter(Boolean);

      await axiosInstance.post(`/orders/${orderData.id}/verify`, {
        pickListId,
        verifiedItems: verifiedProductIds,
      });

      toast({
        title: "Success",
        description: "Order verified successfully",
      });

      setOrderVerified(true);
    } catch (err: any) {
      console.error('Verification error:', err);
      toast({
        variant: "destructive",
        title: "Error",
        description: err.response?.data?.message || "Failed to verify order",
      });
    } finally {
      setVerifying(false);
    }
  };

  const toggleCheckbox = (index: number) => {
    setVerifiedItems((prev) =>
      prev.map((isVerified, idx) => (idx === index ? !isVerified : isVerified))
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (error || !orderData) {
    return (
      <Card className="mx-auto mt-4 max-w-md">
        <CardContent className="p-6 text-center">
          <AlertCircle className="w-12 h-12 mx-auto text-red-500 mb-4" />
          <p className="text-lg font-semibold">Error loading order</p>
          <p className="text-gray-500 mt-2">{error}</p>
          <Button onClick={() => navigate(-1)} className="mt-4">
            Go Back
          </Button>
        </CardContent>
      </Card>
    );
  }

  if (orderVerified) {
    return (
      <div className="min-h-screen bg-gray-50 py-4 px-4 flex flex-col items-center justify-center">
        <Card className="max-w-md w-full">
          <CardHeader>
            <CardTitle className="text-center">Order Verified Successfully</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-center text-green-600 mb-6">
              <CheckCircle className="w-12 h-12 mx-auto mb-2" />
              <p className="text-lg font-medium">Order #{orderData.orderNumber} has been verified</p>
            </div>
            <Button
              className="w-full"
              size="lg"
              onClick={() => navigate(-1)}
            >
              Return to Orders
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 px-4 flex flex-col items-center">
      <Card className="max-w-md w-full">
        <CardHeader>
          <CardTitle>Verify Order #{orderData.orderNumber}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4 text-sm text-gray-500">
            Total Items: {orderData.items.length}
          </div>
          <div className="space-y-4 mb-6">
            {orderData.items.map((item: OrderItem, index: number) => (
              <div
                key={item.productId}
                className={`p-4 rounded-lg border ${
                  verifiedItems[index]
                    ? 'bg-green-50 border-green-200'
                    : 'bg-white border-gray-200'
                }`}
              >
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <div className="font-medium">{item.productTitle}</div>
                    {item.variantTitle && (
                      <div className="text-sm text-gray-500">
                        {item.variantTitle}
                      </div>
                    )}
                    <div className="text-sm font-medium mt-1">
                      Quantity: {item.quantity}
                    </div>
                  </div>
                  <label
                    htmlFor={`verify-item-${index}`}
                    className="flex items-center justify-center w-8 h-8 cursor-pointer"
                  >
                    <input
                      id={`verify-item-${index}`}
                      type="checkbox"
                      checked={verifiedItems[index]}
                      onChange={() => toggleCheckbox(index)}
                      className="w-6 h-6 rounded-full cursor-pointer"
                      aria-label={`Verify ${item.productTitle}`}
                    />
                  </label>
                </div>
              </div>
            ))}
          </div>

          <Button
            className="w-full"
            size="lg"
            disabled={!allItemsVerified || verifying}
            onClick={handleVerify}
          >
            {verifying ? (
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <CheckCircle className="w-4 h-4 mr-2" />
            )}
            {verifying ? 'Verifying...' : 'Confirm Order Packed'}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default VerifyOrderPage;
