import React, { useState, useEffect } from 'react';
import { axiosInstance } from '@/api/client';
import { AlertCircle, Plus, Trash2 } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface Product {
  title: string;
  productId: string;
  variants: Array<{
    id: string | null;
    title: string;
  }>;
}

const CreateOrderForm = () => {
  const [formData, setFormData] = useState({
    customerEmail: '',
    customerName: '',
    items: [{ productTitle: '', variantTitle: '', quantity: 1 }],
    isB2B: false,
    isReplacement: false,
    priority: 'NORMAL',
    notes: ''
  });
  
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoadingProducts(true);
      const response = await axiosInstance.get('/orders/products/unique');
      setProducts(response.data.data);
      console.debug('Fetched products:', response.data.data);
    } catch (err) {
      console.error('Failed to fetch products:', err);
      setError('Failed to load products');
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setSuccess(false);
  
    // Debug log
    console.debug('Submitting order data:', formData);
  
    try {
      const response = await axiosInstance.post('/orders/manual', formData);
      console.debug('Order creation response:', response.data);
      setSuccess(true);
      setFormData({
        customerEmail: '',
        customerName: '',
        items: [{ productTitle: '', variantTitle: '', quantity: 1 }],
        isB2B: false,
        isReplacement: false,
        priority: 'NORMAL',
        notes: ''
      });
    } catch (err: any) {
      console.error('Order creation error:', {
        error: err,
        response: err.response?.data,
        status: err.response?.status,
        data: formData
      });
      setError(err.response?.data?.message || 'Failed to create order');
    } finally {
      setLoading(false);
    }
  };

  const addItem = () => {
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, { productTitle: '', variantTitle: '', quantity: 1 }]
    }));
  };

  const removeItem = (index: number) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const updateItem = (index: number, field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => {
        if (i === index) {
          const updatedItem = { ...item, [field]: value };
          // Clear variant selection when product changes
          if (field === 'productTitle') {
            updatedItem.variantTitle = '';
          }
          return updatedItem;
        }
        return item;
      })
    }));
  };

  const getVariantsForProduct = (productTitle: string) => {
    const product = products.find(p => p.title === productTitle);
    return product?.variants || [];
  };

  if (loadingProducts) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {success && (
        <Card className="bg-green-50 p-4">
          <p className="text-green-800">Order created successfully!</p>
        </Card>
      )}

      {error && (
        <Card className="bg-red-50 p-4">
          <div className="flex items-center gap-2 text-red-800">
            <AlertCircle className="h-5 w-5" />
            <p>{error}</p>
          </div>
        </Card>
      )}

      {/* Customer Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Customer Email
          </label>
          <input
            type="email"
            required
            className="w-full rounded-md border border-gray-300 px-3 py-2"
            value={formData.customerEmail}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              customerEmail: e.target.value
            }))}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Customer Name
          </label>
          <input
            type="text"
            required
            className="w-full rounded-md border border-gray-300 px-3 py-2"
            value={formData.customerName}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              customerName: e.target.value
            }))}
          />
        </div>
      </div>

      {/* Order Items */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Order Items
        </label>
        <div className="space-y-3">
          {formData.items.map((item, index) => (
            <div key={index} className="flex gap-3">
              <Select
                value={item.productTitle}
                onValueChange={(value) => updateItem(index, 'productTitle', value)}
              >
                <SelectTrigger className="flex-1">
                  <SelectValue placeholder="Select product" />
                </SelectTrigger>
                <SelectContent>
                  {products.map((product) => (
                    <SelectItem key={product.title} value={product.title}>
                      {product.title}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {getVariantsForProduct(item.productTitle).length > 0 && (
                <Select
                  value={item.variantTitle}
                  onValueChange={(value) => updateItem(index, 'variantTitle', value)}
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select variant" />
                  </SelectTrigger>
                  <SelectContent>
                    {getVariantsForProduct(item.productTitle).map((variant) => (
                      <SelectItem key={variant.id} value={variant.title}>
                        {variant.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}

              <input
                type="number"
                min="1"
                required
                className="w-24 rounded-md border border-gray-300 px-3 py-2"
                value={item.quantity}
                onChange={(e) => updateItem(index, 'quantity', parseInt(e.target.value))}
              />

              {formData.items.length > 1 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => removeItem(index)}
                  className="text-red-600 hover:text-red-800 hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}
        </div>
        <Button
          type="button"
          variant="ghost"
          onClick={addItem}
          className="mt-2 text-blue-600 hover:text-blue-800 flex items-center gap-1"
        >
          <Plus className="h-4 w-4" />
          Add Another Item
        </Button>
      </div>

      {/* Order Options */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Order Type
          </label>
          <div className="space-x-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600"
                checked={formData.isB2B}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  isB2B: e.target.checked
                }))}
              />
              <span className="ml-2 text-sm text-gray-600">B2B Order</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600"
                checked={formData.isReplacement}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  isReplacement: e.target.checked
                }))}
              />
              <span className="ml-2 text-sm text-gray-600">Replacement Order</span>
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Priority
          </label>
          <Select
            value={formData.priority}
            onValueChange={(value) => setFormData(prev => ({ ...prev, priority: value }))}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select priority" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="LOW">Low</SelectItem>
              <SelectItem value="NORMAL">Normal</SelectItem>
              <SelectItem value="HIGH">High</SelectItem>
              <SelectItem value="URGENT">Urgent</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Notes */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Notes
        </label>
        <textarea
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          rows={3}
          value={formData.notes}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            notes: e.target.value
          }))}
        />
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <Button
          type="submit"
          disabled={loading}
          className="bg-blue-600 text-white hover:bg-blue-700"
        >
          {loading ? 'Creating Order...' : 'Create Order'}
        </Button>
      </div>
    </form>
  );
};

export default CreateOrderForm;