// src/components/PlaceholderPage.tsx

import { useLocation } from 'react-router-dom';

interface PlaceholderPageProps {
  title: string;
  description: string;
}

function PlaceholderPage({ title, description }: PlaceholderPageProps) {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gray-100 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h1 className="text-2xl font-semibold text-gray-900 mb-4">{title}</h1>
          <p className="text-gray-600">
            {description}

            The pathname is: {location.pathname}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PlaceholderPage;