// src/components/picklist/OperationsPickListView.tsx
import { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { 
  Package, 
  Printer, 
  QrCode, 
  CheckCircle, 
  AlertCircle, 
  Loader2, 
  Clock,
  UserCheck 
} from 'lucide-react';
import { pickListApi } from '@/api/pickList';
import { useToast } from '@/components/ui/use-toast';
import { PickList, PickListOrder, PickListStatus } from '@/types/picklist';
import { OrderStatus, OrderPriority } from '@/types/order';
import PickListSummary from './PickListSummary';
import { format } from 'date-fns';

const getPriorityStyle = (priority: OrderPriority) => {
  const styles = {
    URGENT: 'bg-red-100 text-red-800 border-red-300',
    HIGH: 'bg-orange-100 text-orange-800 border-orange-300',
    NORMAL: 'bg-blue-100 text-blue-800 border-blue-300',
    LOW: 'bg-gray-100 text-gray-800 border-gray-300'
  };
  return styles[priority] || styles.NORMAL;
};

const OperationsPickListView = () => {
  const [activePickList, setActivePickList] = useState<PickList | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const navigate = useNavigate();
  const pollingIntervalRef = useRef<NodeJS.Timeout>();

  const fetchActivePickList = async () => {
    try {
      setIsLoading(true);
      const response = await pickListApi.getActivePickList();
      
      if (response.data && response.data.status === PickListStatus.ACTIVE) {
        setActivePickList(response.data);
      } else {
        setActivePickList(null);
      }
    } catch (error) {
      console.error('Error fetching active pick list:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch active pick list"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivePickList();
    // Set up polling interval
    pollingIntervalRef.current = setInterval(fetchActivePickList, 30000);
    
    // Cleanup interval on unmount
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  const handlePrintDocuments = async (type: 'labels' | 'slips') => {
    if (!activePickList) return;
    
    try {
      setIsPrinting(true);
      const response = await (type === 'labels' 
        ? pickListApi.printLabels(activePickList.id)
        : pickListApi.printPackingSlips(activePickList.id));
      
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}-${activePickList.sequence}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
      toast({
        title: "Success",
        description: `${type === 'labels' ? 'Labels' : 'Packing slips'} printed successfully`
      });
      
      fetchActivePickList();
    } catch (error) {
      console.error(`Error printing ${type}:`, error);
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to print ${type}`
      });
    } finally {
      setIsPrinting(false);
    }
  };

  const handleVerifyOrder = (orderId: string) => {
    if (!activePickList) return;
    
    // Stop polling before navigating
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }
    
    navigate(`/verify-order/${orderId}`, {
      state: { 
        pickListId: activePickList.id,
        fromPickList: true
      }
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (!activePickList) {
    return (
      <Card className="mx-auto mt-8">
        <CardContent className="p-6 text-center">
          <AlertCircle className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium">No Active Pick List</h3>
          <p className="text-gray-500 mt-2">
            There are no pick lists currently active.
          </p>
        </CardContent>
      </Card>
    );
  }

  const isPickListCompleted = activePickList.status === PickListStatus.COMPLETED;

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold">
            Pick List #{activePickList.sequence}
          </h1>
          {isPickListCompleted && activePickList.completedAt && (
            <p className="text-sm text-green-600">
              Completed on {format(new Date(activePickList.completedAt), 'MMM dd, yyyy HH:mm')}
            </p>
          )}
        </div>
        
        {!isPickListCompleted && (
          <div className="flex space-x-4">
            <Button
              variant="outline"
              onClick={() => handlePrintDocuments('labels')}
              disabled={isPrinting}
            >
              {isPrinting ? (
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <Printer className="w-4 h-4 mr-2" />
              )}
              Print Labels
            </Button>
            <Button
              variant="outline"
              onClick={() => handlePrintDocuments('slips')}
              disabled={isPrinting}
            >
              {isPrinting ? (
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <QrCode className="w-4 h-4 mr-2" />
              )}
              Print Packing Slips
            </Button>
          </div>
        )}
      </div>

      {!isPickListCompleted && <PickListSummary orders={activePickList.orders} />}

      <div className="grid gap-4">
        {activePickList.orders.map((pickListOrder: PickListOrder) => (
          <Card 
            key={pickListOrder.id}
            className={`transition-all ${
              pickListOrder.status === OrderStatus.READY_TO_SHIP 
                ? 'bg-green-50' 
                : 'bg-white'
            }`}
          >
            <CardContent className="p-6">
              <div className="flex items-start justify-between">
                <div className="space-y-2">
                  <div className="flex items-center space-x-3">
                    <span className="text-lg font-semibold">
                      #{pickListOrder.order.orderNumber}
                    </span>
                    <span className={`px-3 py-1 rounded-full text-sm ${
                      getPriorityStyle(pickListOrder.order.priority as OrderPriority)
                    }`}>
                      {pickListOrder.order.priority}
                    </span>
                  </div>

                  <div className="text-sm text-gray-600">
                    {pickListOrder.order.customer.name}
                  </div>

                  <div className="space-y-2 mt-4">
                    {pickListOrder.order.items.map((item, index) => (
                      <div key={index} className="flex items-center text-sm">
                        <Package className="w-4 h-4 text-gray-400 mr-2" />
                        <span>
                          {item.quantity}x {item.productTitle}
                          {item.variantTitle && (
                            <span className="text-gray-500">
                              {' '}({item.variantTitle})
                            </span>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col items-end space-y-2">
                  {pickListOrder.status === OrderStatus.READY_TO_SHIP ? (
                    <div className="flex flex-col items-end">
                      <div className="flex items-center text-green-600">
                        <UserCheck className="w-5 h-5 mr-2" />
                        <span className="text-sm">Verified</span>
                      </div>
                      {pickListOrder.completedAt && (
                        <span className="text-xs text-gray-500 mt-1">
                          {format(new Date(pickListOrder.completedAt), 'MMM dd, yyyy HH:mm')}
                        </span>
                      )}
                    </div>
                  ) : pickListOrder.order.status === OrderStatus.PACKED ? (
                    <Button
                      onClick={() => handleVerifyOrder(pickListOrder.order.id)}
                      className="bg-green-600 hover:bg-green-700"
                    >
                      <CheckCircle className="w-4 h-4 mr-2" />
                      Verify Order
                    </Button>
                  ) : (
                    <div className="flex items-center text-blue-600">
                      <Clock className="w-5 h-5 mr-2" />
                      Awaiting Packing
                    </div>
                  )}
                  
                  <span className="text-sm text-gray-500">
                    Sequence: #{pickListOrder.sequence}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default OperationsPickListView;
