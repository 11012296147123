//packages/frontend/src/types/order.ts
export enum OrderStatus {
  PLACED = 'PLACED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  PACKED = 'PACKED',
  READY_TO_SHIP = 'READY_TO_SHIP',
  SHIPPED = 'SHIPPED'
}

export enum OrderPriority {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  URGENT = 'URGENT'
}

export enum PickListStatus {
  PLANNED = 'PLANNED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export interface OrderItem {
  productId: string;
  productTitle: string;
  variantTitle?: string;
  quantity: number;
}

export interface Customer {
  name: string;
  email: string;
}

export interface PickList {
  id: string;
  status: PickListStatus;
}

export interface PickListOrder {
  pickList: PickList;
}

export interface Order {
  id: string;
  orderNumber: string;
  status: OrderStatus;
  priority: OrderPriority;
  customer: Customer;
  items: OrderItem[];
  isB2B: boolean;
  isReplacement: boolean;
  tracking: TrackingInfo[];
  notes?: Array<{
    content: string;
    createdAt: string;
    createdBy: string;
  }>;
  verifiedAt?: string;
  createdAt: string;
  pickListOrders?: PickListOrder[];
}

export interface TrackingInfo {
  trackingNumber: string | null;
  trackingUrl: string | null;
  carrier: string;
}

export interface OrderDetailsModalProps {
  order: Order | null;
  onClose: () => void;
}

export interface VerifyOrderParams {
  pickListId: string;
  orderId: string;
  verificationNotes: string;
}

export interface OrderFilters {
  status?: string;
  priority?: string;
  search?: string;
}
