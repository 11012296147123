import React, { useState, useEffect, useCallback, useRef } from 'react';
import { axiosInstance } from '../api/client';
import OrderDetailsModal from './OrderDetailsModal';
import debounce from 'lodash/debounce';
import { OrderFilters, Order } from '../types/order';
import {
  Clock,
  Package,
  Truck,
  CheckCircle,
  AlertCircle,
  Filter,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

// Constants
const ORDERS_PER_PAGE = 400;

// Column definitions for the order board
const statusColumns = [
  {
    id: 'PLACED',
    label: 'Order Placed',
    icon: Clock,
    color: 'bg-yellow-50 border-yellow-200'
  },
  {
    id: 'IN_PRODUCTION',
    label: 'Being Produced',
    icon: Package,
    color: 'bg-blue-50 border-blue-200'
  },
  {
    id: 'READY_TO_SHIP',
    label: 'Ready to Ship',
    icon: Package,
    color: 'bg-green-50 border-green-200'
  },
  {
    id: 'SHIPPED',
    label: 'En Route',
    icon: Truck,
    color: 'bg-purple-50 border-purple-200'
  },
  {
    id: 'DELIVERED',
    label: 'Delivered',
    icon: CheckCircle,
    color: 'bg-gray-50 border-gray-200'
  },
];

const priorityOrder = {
  'URGENT': 0,
  'HIGH': 1,
  'NORMAL': 2,
  'LOW': 3
};

const OrderBoard: React.FC = () => {
  // State management
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [filters] = useState<OrderFilters>({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeColumnIndex, setActiveColumnIndex] = useState(0);

  const filtersRef = useRef<OrderFilters>({});
  const searchInputRef = useRef<HTMLInputElement>(null);

  // Create debounced search function
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      filtersRef.current = {
        ...filtersRef.current,
        search: searchTerm || undefined
      };
      fetchOrders(1, false);
      fetchTotalCount();
    }, 300),
    []
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Fetch orders function
  const fetchOrders = async (pageNum = 1, append = false) => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams({
        page: pageNum.toString(),
        limit: ORDERS_PER_PAGE.toString(),
        ...(filtersRef.current.status && { status: filtersRef.current.status }),
        ...(filtersRef.current.priority && { priority: filtersRef.current.priority }),
        ...(filtersRef.current.search && { search: filtersRef.current.search })
      });

      const response = await axiosInstance.get(`/orders?${queryParams}`);

      if (append) {
        setOrders(prev => [...prev, ...response.data.data]);
      } else {
        setOrders(response.data.data);
      }
      setError('');
    } catch (err) {
      setError('Failed to load orders');
      console.error('Error fetching orders:', err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch total count
  const fetchTotalCount = async () => {
    try {
      const response = await axiosInstance.get('/orders/count', {
        params: filtersRef.current
      });
      setTotalOrdersCount(response.data.total);
    } catch (err) {
      console.error('Error fetching total count:', err);
    }
  };

  // Effect for fetching orders when filters or page changes
  useEffect(() => {
    fetchOrders(1, false);
    fetchTotalCount();
  }, []); // Only run on mount

  // Effect for fetching total count when filters change
  useEffect(() => {
    fetchTotalCount();
  }, [filters]);

  // Event handlers
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  const handleOrderClick = (order: Order) => {
    setSelectedOrder(order);
  };

  const handleFilterChange = (filterType: string, value: string) => {
    filtersRef.current = {
      ...filtersRef.current,
      [filterType]: value || undefined
    };
    fetchOrders(1, false);
    fetchTotalCount();
  };

  const handleColumnChange = (direction: 'prev' | 'next') => {
    if (direction === 'prev' && activeColumnIndex > 0) {
      setActiveColumnIndex(activeColumnIndex - 1);
    } else if (direction === 'next' && activeColumnIndex < statusColumns.length - 1) {
      setActiveColumnIndex(activeColumnIndex + 1);
    }
  };

  // Utility functions
  const getOrdersByStatus = (status: string) => {
    return orders
      .filter(order => order.status === status)
      .sort((a, b) => {
        // First sort by priority
        const priorityDiff = priorityOrder[a.priority] - priorityOrder[b.priority];
        if (priorityDiff !== 0) return priorityDiff;

        // Then sort by date (oldest first)
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });
  };

  const getPriorityColor = (priority: Order['priority']) => {
    switch (priority) {
      case 'URGENT':
        return 'bg-red-100 text-red-800';
      case 'HIGH':
        return 'bg-orange-100 text-orange-800';
      case 'NORMAL':
        return 'bg-blue-100 text-blue-800';
      case 'LOW':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Filter Panel Component
  const FilterPanel = () => (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="flex flex-col sm:flex-row gap-4">
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search orders..."
          className="px-3 py-2 border rounded-md w-full sm:w-auto flex-1"
          onChange={handleSearchChange}
          defaultValue={filtersRef.current.search}
        />
        <select
          className="px-3 py-2 border rounded-md w-full sm:w-auto"
          onChange={(e) => handleFilterChange('priority', e.target.value)}
          value={filtersRef.current.priority || ''}
        >
          <option value="">All Priorities</option>
          <option value="URGENT">Urgent</option>
          <option value="HIGH">High</option>
          <option value="NORMAL">Normal</option>
          <option value="LOW">Low</option>
        </select>
      </div>
    </div>
  );

  // Loading state
  if (loading && orders.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg">Loading orders...</div>
      </div>
    );
  }

  // Error state
  if (error && orders.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-red-500 flex items-center gap-2">
          <AlertCircle size={20} />
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow"
        >
          <Filter size={20} />
          Filters
        </button>
        <div className="text-sm text-gray-600">
          Showing {orders.length} of {totalOrdersCount} orders
        </div>
      </div>

      {isFilterOpen && <FilterPanel />}

      {/* Mobile Column Navigation */}
      <div className="flex items-center justify-between mb-4 lg:hidden">
        <button
          onClick={() => handleColumnChange('prev')}
          disabled={activeColumnIndex === 0}
          className="p-2 rounded-lg bg-white shadow disabled:opacity-50"
        >
          <ChevronLeft size={20} />
        </button>
        <span className="font-medium">
          {statusColumns[activeColumnIndex].label}
        </span>
        <button
          onClick={() => handleColumnChange('next')}
          disabled={activeColumnIndex === statusColumns.length - 1}
          className="p-2 rounded-lg bg-white shadow disabled:opacity-50"
        >
          <ChevronRight size={20} />
        </button>
      </div>

      {/* Desktop View */}
      <div className="hidden lg:flex space-x-4 p-4 overflow-x-auto">
        {statusColumns.map(column => {
          const columnOrders = getOrdersByStatus(column.id);
          const Icon = column.icon;

          return (
            <div
              key={column.id}
              className={`flex-shrink-0 w-80 rounded-lg p-4 border ${column.color}`}
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <Icon size={20} className="text-gray-700" />
                  <h3 className="font-semibold text-gray-700">{column.label}</h3>
                </div>
                <div className="flex flex-col items-end">
                  <span className="px-2 py-1 bg-white rounded-full text-sm font-medium text-gray-600">
                    {columnOrders.length}
                  </span>
                  {columnOrders.filter(order => order.priority === 'URGENT').length > 0 && (
                    <span className="text-xs text-red-600 mt-1">
                      {columnOrders.filter(order => order.priority === 'URGENT').length} urgent
                    </span>
                  )}
                  {columnOrders.filter(order => order.priority === 'HIGH').length > 0 && (
                    <span className="text-xs text-orange-600">
                      {columnOrders.filter(order => order.priority === 'HIGH').length} high
                    </span>
                  )}
                </div>
              </div>

              <div className="space-y-3 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 250px)' }}>
                {columnOrders.length === 0 ? (
                  <div className="text-center py-8 text-gray-500 bg-white rounded-lg border border-dashed">
                    No orders
                  </div>
                ) : (
                  columnOrders.map(order => (
                    <div
                      key={order.id}
                      onClick={() => handleOrderClick(order)}
                      className={`bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer border border-gray-100
                        ${order.priority === 'URGENT' ? 'border-l-4 border-l-red-500' : ''}
                        ${order.priority === 'HIGH' ? 'border-l-4 border-l-orange-500' : ''}
                      `}>
                      <div className="flex justify-between items-start mb-2">
                        <div className="flex flex-col">
                          <span className="font-medium">#{order.orderNumber}</span>
                          <span className="text-sm text-gray-600">{order.customer.name}</span>
                        </div>
                        <span className={`text-xs px-2 py-1 rounded-full ${getPriorityColor(order.priority)}`}>
                          {order.priority}
                        </span>
                      </div>
                      <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-100">
                        <div className="text-xs text-gray-500">
                          {formatDate(order.createdAt)}
                        </div>
                        <div className="text-xs font-medium">
                          {order.items?.length || 0} items
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Mobile View */}
      <div className="lg:hidden">
        {(() => {
          const column = statusColumns[activeColumnIndex];
          const columnOrders = getOrdersByStatus(column.id);
          const Icon = column.icon;

          return (
            <div className={`rounded-lg p-4 border ${column.color}`}>
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <Icon size={20} className="text-gray-700" />
                  <h3 className="font-semibold text-gray-700">{column.label}</h3>
                </div>
                <div className="flex flex-col items-end">
                  <span className="px-2 py-1 bg-white rounded-full text-sm font-medium text-gray-600">
                    {columnOrders.length}
                  </span>
                  {columnOrders.filter(order => order.priority === 'URGENT').length > 0 && (
                    <span className="text-xs text-red-600 mt-1">
                      {columnOrders.filter(order => order.priority === 'URGENT').length} urgent
                    </span>
                  )}
                </div>
              </div>

              <div className="space-y-3">
                {columnOrders.length === 0 ? (
                  <div className="text-center py-8 text-gray-500 bg-white rounded-lg border border-dashed">
                    No orders
                  </div>
                ) : (
                  columnOrders.map(order => (
                    <div
                      key={order.id}
                      onClick={() => handleOrderClick(order)}
                      className={`bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer border border-gray-100
                        ${order.priority === 'URGENT' ? 'border-l-4 border-l-red-500' : ''}
                        ${order.priority === 'HIGH' ? 'border-l-4 border-l-orange-500' : ''}
                      `}>
                      <div className="flex justify-between items-start mb-2">
                        <div className="flex flex-col">
                          <span className="font-medium">#{order.orderNumber}</span>
                          <span className="text-sm text-gray-600">{order.customer.name}</span>
                        </div>
                        <span className={`text-xs px-2 py-1 rounded-full ${getPriorityColor(order.priority)}`}>
                          {order.priority}
                        </span>
                      </div>
                      <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-100">
                        <div className="text-xs text-gray-500">
                          {formatDate(order.createdAt)}
                        </div>
                        <div className="text-xs font-medium">
                          {order.items?.length || 0} items
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          );
        })()}
      </div>

      <OrderDetailsModal
        order={selectedOrder}
        onClose={() => setSelectedOrder(null)}
      />
    </div>
  );
};

export default OrderBoard;
