// src/api/auth.ts
import { axiosInstance } from './client';
import { LoginCredentials, LoginResponse, ProfileResponse } from '../types/auth';

export const login = async (credentials: LoginCredentials): Promise<LoginResponse> => {
  const response = await axiosInstance.post<LoginResponse>('/auth/login', credentials);
  return response.data;
};

export const getProfile = async (): Promise<ProfileResponse> => {
  const response = await axiosInstance.get<ProfileResponse>('/auth/profile');
  return response.data;
};
